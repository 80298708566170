import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { Link as RLink } from "react-scroll";
import * as Yup from 'yup';

import content from 'config/content.json';
import './MailForm.scss';

const apiUrl = 'https://communityspot.herokuapp.com/api/mail';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('E-mail is not valid!').required('E-mail is required!')
});

const wWidth = window.innerWidth;
const mePlaceholder     = wWidth >= 576 ? 'me@mycommunitycentre.com' : 'your email';
const friendPlaceholder = wWidth >= 576 ? 'myfriend@theircommunitycentre.com' : 'their email';
const okPlaceholder     = 'Email sent!';

class MailForm extends Component {
  state = {
    isSubmitting: false,
    isFade: false,
    isFadeIcon: false,
    submitCount: 0,
    emailFriend: null
  }
  titleRef    = React.createRef();
  subtitleRef = React.createRef();

  handleSubmit = (values, actions) => {
    const { email } = values;
    const { emailFriend, submitCount } = this.state;

    if (submitCount === 2) {
      return;
    }

    axios.post(apiUrl, { email, emailFriend })
      .then(() => { 
        this.setState({ isFade: true, isFadeIcon: true }, () => {
          setTimeout(() => {
            actions.resetForm({});
            this.setState((state) => {
              let updatedState = {
                isFade: false,
                isFadeIcon: false,
                isSubmitting: true,
                submitCount: state.submitCount + 1
              }

              if (state.submitCount + 1 === 1) {
                updatedState.emailFriend = email;
              }

              return updatedState;
              
            }, () => {
              setTimeout(this.resetSubmittingView, 500);
            });
          }, 500);
        });
        
      })
      .catch(error => { console.log(error) });
  }

  resetSubmittingView = () => {
    this.setState({ isFadeIcon: true }, () => {
      setTimeout(() => {
        this.setState({ isFadeIcon: false, isSubmitting: false });
      }, 500);
    });
  }
  
  render() {
    const { isContentToBottom, toggle, closeMenu } = this.props;
    const { submitCount, isFade, isFadeIcon, isSubmitting } = this.state;

    const statusText = submitCount >= 1 ? 'after' : 'before';
    const title = content[`section_email_form_title_${statusText}`];
    const subtitle = content[`section_email_form_text_${statusText}`];
    const bottomContent = isContentToBottom ? content[`mail_form_bottom_content_${statusText}`] : '';
    const icon = submitCount === 2 ? 'fa-check' :
      !isSubmitting ? 'fa-arrow-right' : 'fa-check';

    const placeholder = submitCount === 0 ? mePlaceholder
      : submitCount === 1 ? friendPlaceholder : okPlaceholder;

    return (
      <div className="mail-form">
        <h2 className={`title ${isFade && submitCount === 0 ? 'fade' : ''}`}>{title}</h2>
        <h4 className={`subtitle ${isFade && submitCount === 0 ? 'fade' : ''}`} dangerouslySetInnerHTML={subtitle} />
        <div className="form-wrapper text-center">
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            render={(props) => {
              const { values, errors, touched } = props;
              const isError = field => errors[field] && touched[field];
              return (
                <Form>
                  <Field
                    type="email"
                    name="email"
                    placeholder={placeholder}
                    value={values.email || ''}
                    className={`${isError('email') && submitCount !== 2 ? 'is-invalid' : ''}`}
                  />
                  <button type="submit" id="myBtn">
                    <i className={`fas ${icon} ${isFadeIcon && submitCount !== 2 ? 'fade' : ''}`}></i>
                  </button>
                </Form>
              )
            }}
          />
        </div>
        {bottomContent &&
          <div className={`content-bottom text-center ${isFade && submitCount === 0 ? 'fade' : ''}`} >
            <span dangerouslySetInnerHTML={bottomContent} />{` `}
            {submitCount === 0 &&
              <RLink to="section2" smooth={true} delay={300}>
                <span onClick={()=> {
                  toggle();
                  closeMenu();
                }}>Check out why</span>
              </RLink>
            }
          </div>
        }
      </div>
    );
  }
}

export default MailForm;
