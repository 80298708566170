import React from 'react';

import { isRetina } from 'utils/common';
import content from 'config/content.json';
import './Footer.scss'
import facebookFree from './images/icon-facebook-free@1x.png';
import facebookFree2x from './images/icon-facebook-free@2x.png';


function Footer(){
  return (
    <footer>
      <div className="container">
        <div className="row align-items-center">
          <div className="col text-center text-sm-left">
            <div className="logo">
              <span>
                {content.site_title}
              </span>
            </div>
          </div>
          <div className="col facebookFree">
            <a href="https://m.signalvnoise.com/become-a-facebook-free-business/" target="_blank" rel="noopener noreferrer">
              <img src={isRetina(facebookFree, facebookFree2x)} alt=""/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
