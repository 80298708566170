import React from 'react';

import MailForm from 'components/MailForm';
import './SectionMailForm.scss';

function SectionMailForm() {
  return (
    <section className="bg-green">
      <div className="container section-email-form">
        <div className="eclipse"></div>
        <div className="green">
          <MailForm />
        </div>
      </div>
    </section>
  );
}

export default SectionMailForm;
